import { render, staticRenderFns } from "./unsupported-browser.vue?vue&type=template&id=2cb5b6fc&scoped=true&"
import script from "./unsupported-browser.vue?vue&type=script&lang=js&"
export * from "./unsupported-browser.vue?vue&type=script&lang=js&"
import style0 from "./unsupported-browser.vue?vue&type=style&index=0&id=2cb5b6fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb5b6fc",
  null
  
)

export default component.exports
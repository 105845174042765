<template>
    <Dialog v-model="showCreditIntermediaryInfo">
        <h1 class="mb-4">INFORMACJA POŚREDNIKA KREDYTOWEGO</h1>
        <p><b>Comperia.pl S.A.</b> z siedzibą w Warszawie, ul. Konstruktorska 13, 2-673 Warszawa (Comperia) jest pośrednikiem kredytowym, została wpisana do rejestru pośredników kredytowych w zakresie kredytów konsumenckich, prowadzonego przez Komisję Nadzoru Finansowego, pod numerem RPK001025.</p>
        <p>Comperia w ramach pośrednictwa kredytowego umożliwia złożenie wniosku o kredyt konsumencki za pośrednictwem strony <a href="https://comfino.pl" target="_blank">https://comfino.pl</a>, udzielany przez banki lub instytucje kredytowe współpracujące z Comperia.</p>
        <p>Comperia współpracuje z następującymi Kredytodawcami i Pożyczkodawcami:</p>
        <ul>
            <li>Twisto Polska Sp. z o. o. z siedzibą w Warszawie przy ul. Puławskiej 2 (02--566 Warszawa), wpisaną do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000689624, posiadającą kapitał zakładowy w wysokości 6 627 950,00 PLN, opłacony w pełni, i numer NIP: 951244875</li>
            <li>Alior Bank Spółka Akcyjna z siedzibą w Warszawie przy ul. Łopuszańskiej 38D, kod pocztowy: 02-232 Warszawa, wpisaną do rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem 0000305178, o numerze NIP 1070010731, o numerze REGON 141387142, z kapitałem zakładowym w wysokości 1 305 539 910 PLN PLN, opłaconym w całości</li>
            <li>Brutto sp. z o.o., z siedzibą w Warszawie przy ul. Inżynierska 8, 03-422 Warszawa, wpisana do rejestru przedsiębiorców KRS prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000631388, NIP 7123316804, kapitał zakładowy 292 400,00 (słownie: dwieście dwanaście tysięcy) PLN, opłacony w całości.</li>
            <li>Santander Consumer Bank S.A. z siedzibą we Wrocławiu, ul. Legnicka 48 B, 54 – 202 Wrocław, zarejestrowaną w rejestrze przedsiębiorców Krajowego Rejestru Sądowego prowadzonym przez Sąd Rejonowy dla Wrocławia-Fabrycznej, VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000040562, o kapitale zakładowym wpłaconym w całości w wysokości 520.000.000,00 PLN, NIP: 527-20-46-102</li>
            <li>Bank Polska Kasa Opieki Spółka Akcyjna z siedzibą w Warszawie, ul. Grzybowska 53/57, 00-844 Warszawa, wpisany do rejestru przedsiębiorców w Sądzie Rejonowym dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, KRS: 0000014843, NIP: 526-00-06-841, REGON: 000010205, wysokość kapitału zakładowego i kapitału wpłaconego: 262 470 034 zł.</li>
            <li>PragmaGO S.A. z siedzibą w Katowicach, adres: ul. Brynowska 72, 40-584 Katowice, wpisaną do rejestru przedsiębiorców przez Sąd Rejonowy Katowice-Wschód w Katowicach Wydział VIII Gospodarczy KRS, pod nr KRS 0000267847, posiadającą nr REGON: 277573126, NIP: 634-24-27-710, z kapitałem zakładowym w wysokości 6.891.041,00 zł w całości opłaconym.</li>
            <li>Smartney Grupa Oney Spółka Akcyjna z siedzibą w Warszawie, adres: ul. Krakowiaków 46, 02-255 Warszawa, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000204413, REGON: 015707452 NIP: 5272429317, o kapitale zakładowym w wysokości 4 383 360,00 złotych opłacony w całości.</li>
            <li>Leaselink Sp. z o.o., z siedzibą w Warszawie (03–840) przy ul. Grochowskiej 306/308, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Miasta Stołecznego Warszawy, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000477046, NIP: 5272698282 oraz REGON: 146815482, kapitał zakładowy 210 600,00 zł.</li>
            <li>PayPo Sp. z o.o. z siedzibą w Warszawie, ul. Domaniewska 39 (02-672 Warszawa), wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000575158, o kapitale zakładowym w wysokości 1 174 750,00 złotych, NIP: 521-37-05-997, Regon: 362485126. Spółka wpisana do rejestru instytucji pożyczkowych pod numerem RIP000135 oraz rejestru krajowych instytucji płatniczych pod numerem IP59/2024 prowadzonych przez Komisję Nadzoru Finansowego.</li>
        </ul>
        <p class="mt-4">Kredytobiorca/Pożyczkobiorca zawiera umowę o kredyt/pożyczkę z Kredytodawcą/Pożyczkodawcą wskazanym na liście powyżej.</p>
        <p>Comperia otrzymuje wynagrodzenie od Kredytodawcy/Pożyczkodawcy, określone w odrębnych umowach między Comperia a Kredytodawcą/Pożyczkodawcą.</p>
        <p>Wynagrodzenie to nie wpływa na warunki finansowe kredytu/pożyczki oferowanej potencjalnemu Kredytobiorcy/Pożyczkobiorcy.</p>
        <p>Przyznanie kredytu/pożyczki uzależnione jest od pozytywnej oceny zdolności kredytowej potencjalnego Kredytobiorcy/Pożyczkobiorcy przez Kredytodawcę/Pożyczkodawcę oraz od akceptacji przez potencjalnego Kredytobiorcę/Pożyczkobiorcę ostatecznych warunków zaproponowanych przez Kredytodawcę/Pożyczkodawcę.</p>
        <p>Niniejszy materiał ma charakter wyłącznie informacyjny i nie stanowi oferty w rozumieniu kodeksu cywilnego.</p>
    </Dialog>
</template>

<script>
import Dialog from '~/components/partials/dialog/ecommerce/Dialog'
import CreditIntermediaryInfo from '../credit-intermediary-info'

export default {
    components: {
        Dialog,
    },
    mixins: [CreditIntermediaryInfo],
}
</script>

<template>
    <div v-if="!isLocalStorageEnabled">
        <UnsupportedBrowser />
    </div>
    <div
        v-else
        class="comfino-error-wrapper"
    >
        <ErrorToolbarMobile class="hidden-lg-and-up" />
        <ErrorToolbarDesktop class="hidden-md-and-down" />
        <section class="main-error-container">
            <div class="error">
                <img
                    class="error__img"
                    src="@/static/error/comfinus.svg"
                    alt="obrazek Comfinusia"
                />
                <div class="error__title">
                    <h1>Oj, coś nam się zaplątało!</h1>
                </div>
                <div class="error__subtitle">
                    <p>
                        Mamy techniczny problem i wniosek nie chce się dokończyć.
                        Odśwież stronę lub wróć za moment – już nad tym pracujemy!
                    </p>
                </div>
                <div class="error__info-note">
                    <p>Jeśli kłopot się powtórzy, daj nam znać.</p>
                </div>
                <div class="error__code">
                    <p>kod błędu: <span>{{ errorCodeText }}</span></p>
                </div>
            </div>
        </section>
        <ErrorFooter class="footer" />
        <link
            rel="preconnect"
            href="https://fonts.googleapis.com"
        >
        <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossorigin
        >
        <link
            href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap"
            rel="stylesheet"
        >
    </div>
</template>

<script>
import UnsupportedBrowser from '../pages/ecommerce/unsupported-browser.vue'
import ErrorToolbarMobile from '~/components/partials/toolbar/ecommerce/ErrorToolbarMobile'
import ErrorToolbarDesktop from '~/components/partials/toolbar/ecommerce/ErrorToolbarDesktop'
import ErrorFooter from '~/components/partials/footer/ecommerce/ErrorFooter'

export default {
    components: {
        UnsupportedBrowser,
        ErrorToolbarMobile,
        ErrorToolbarDesktop,
        ErrorFooter,
    },
    layout: 'error',
    props: ['error'], // eslint-disable-line
    computed: {
        errorCodeText () {
            let errorCodeWithDescription = this.error.statusCode
            switch (this.error.statusCode) {
                case 400:
                    errorCodeWithDescription += ' - Bad Request'
                    break
                case 401:
                    errorCodeWithDescription += ' - Unauthorized'
                    break
                case 403:
                    errorCodeWithDescription += ' - Forbidden'
                    break
                case 404:
                    errorCodeWithDescription += ' - Not Found'
                    break
                case 405:
                    errorCodeWithDescription += ' - Method Not Allowed'
                    break
                case 500:
                    errorCodeWithDescription += ' - Internal Server Error'
                    break
                case 502:
                    errorCodeWithDescription += ' - Bad Request'
                    break
                case 503:
                    errorCodeWithDescription += ' - Service Unavailable'
                    break
                case 504:
                    errorCodeWithDescription += ' - Gateway Timeout'
                    break
                default:
                    break
            }
            return errorCodeWithDescription
        },
        isLocalStorageEnabled () {
            try {
                const key = '__storage__test'
                window.localStorage.setItem(key, null)
                window.localStorage.removeItem(key)
                return true
            } catch (_) {
                return false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.v-application {
    .error {
        background-color: transparent !important;
    }
}

.comfino-error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;
    background-image: url('@/static/backgrounds/background_ecommerce_desktop.jpg');
    background-size: cover;
}

.main-error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 65px 130px;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    font-family: 'Plus Jakarta Sans';
    font-size: 24px;
    text-align: center;
    color: #878787;

    p {
        margin: 0;
    }

    &__img {
        max-width: 290px;
        width: 100%;
        margin-bottom: -16px;
    }

    &__title {
        font-size: 64px;

        > h1 {
            font-size: 1em;
            font-weight: 800;
            line-height: 80px;
        }
    }

    &__subtitle {
        font-weight: 800;
    }

    &__info-note {
        font-weight: 800;
    }

    &__code {
        font-size: 28px;
        font-weight: 300;
    }
}

.footer {
    display: none;
}

@media (max-width: 1263px) {
    .comfino-error-wrapper {
        padding: 68px 0 60px;
    }
    .main-error-container {
        padding: 26px 26px 120px;
    }
    .error {
        font-size: 14px;
        gap: 16px;

        &__img {
            max-width: 220px;
            width: 50%;
        }

        &__title {
            font-size: 34px;

            > h1 {
                line-height: 42px;
            }
        }

        &__subtitle {
            font-weight: 500;
        }

        &__code {
            font-size: 14px;
        }
    }
    .footer {
        display: block;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
</style>

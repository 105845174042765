<template>
    <header class="toolbar-desktop">
        <div class="toolbar">
            <div class="toolbar__wrapper">
                <div class="logo">
                    <img
                        src="/logo-comfino.svg"
                        alt="logo Comfino"
                        height="70"
                    />
                </div>
                <ErrorFooter class="error_footer" />
            </div>
        </div>
    </header>
</template>

<script>
import ErrorFooter from '~/components/partials/footer/ecommerce/ErrorFooter'

export default {
    components: {
        ErrorFooter,
    },
}
</script>

<style lang="scss" scoped>
$toolbar-width: 320px;
$toolbar-width-lg: 432px;
.toolbar-desktop {
    min-width: $toolbar-width;
    max-width: $toolbar-width;
}
.toolbar {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 60px 54px 40px;
    width: $toolbar-width;
    height: 100vh;
    background: #fff;
    &__wrapper {
        display: flex;
        flex-direction: column;
        .logo {
            width: 190px;
        }
        .error_footer {
            margin-top: auto;
        }
    }
}
@media (min-width: 1400px) {
    .toolbar-desktop {
        min-width: $toolbar-width-lg;
        max-width: $toolbar-width-lg;
    }
    .toolbar {
        width: $toolbar-width-lg;
    }
}
</style>

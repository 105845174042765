import dayjs from 'dayjs'

export default function getComfinoBaseParams ({ app, store }) {
    const { route } = app.context
    const { cart, initialProductType } = store.state.ecommerce
    const { isCash } = store.state

    function getProductsNames () {
        let productsName = ''
        cart.products.forEach((product, index) => {
            productsName += `${index ? '+' : ''}${product.name}`
        })
        return productsName
    }

    const {
        chosenProduct,
        chosenOffer,
    } = store.state.analytics.comfino

    const storeReturnUrl = new URL(store.state.ecommerce.storeReturnUrl)
    return {
        token: route.query.token || null,
        timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'),
        sceuidjs: store.state.trackerId,
        rescue: store.state.analytics.rescue,
        page_path: route.path,
        ecommerce_store: storeReturnUrl.hostname,
        ecommerce_store_id: store.state.ecommerce.store.uuid,
        utm_source: route.query.utm_source || null,
        utm_medium: route.query.utm_medium || null,
        utm_campaign: route.query.utm_campaign || null,
        utm_term: route.query.utm_term || null,
        utm_content: route.query.utm_content || null,
        products_number: cart.products.length,
        products_name: getProductsNames(),
        products_sum_price: cart.totalAmount,
        products_delivery_cost: cart.deliveryCost,
        chosen_product: chosenProduct,
        initial_product_type: initialProductType,
        chosen_creditor_name: chosenOffer.creditorName,
        chosen_loan_term: chosenOffer.loanTerm,
        chosen_installment_amount: (chosenOffer.installmentAmount / 100).toFixed(2),
        rrso: chosenOffer.rrso,
        isCash,
    }
}

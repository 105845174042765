<template>
    <header class="toolbar-desktop">
        <div class="toolbar">
            <div class="toolbar__wrapper">
                <div class="logo">
                    <img
                        src="/logo-comfino.svg"
                        alt="logo Comfino"
                        height="70"
                    />
                </div>
                <div
                    v-if="account"
                    class="logged-user mb-5 mt-5"
                >
                    <span>Zalogowany jako</span><br>
                    <b>{{ account.firstName }}  {{ account.lastName }}</b><br>
                    <a
                        :href="accountAddress"
                        target="_blank"
                    >
                        Twoje konto klienta
                    </a>
                </div>
                <div
                    v-if="store && store.logoUrl"
                    class="shop-logo"
                >
                    Partner biznesowy
                    <img
                        :src="store.logoUrl"
                        :alt="store.name"
                    />
                </div>
                <div
                    v-if="tabsVisible"
                    class="progress"
                >
                    <div
                        v-for="(tab, index) in tabs"
                        :key="index"
                        class="progress__item"
                        :class="{
                            active: index === activeTabIndex,
                            done: index < activeTabIndex
                        }"
                    >
                        <div class="icon">
                            <img
                                v-if="index < activeTabIndex"
                                src="/icons/toolbar_done.svg"
                                alt="ikona"
                            />
                            <img
                                v-if="index === activeTabIndex"
                                src="/icons/toolbar_active.svg"
                                alt="ikona"
                            />
                        </div>
                        {{ tab.label }}
                    </div>
                </div>
                <Contact />
                <Footer class="footer" />
            </div>
        </div>
    </header>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Contact from '~/components/partials/contact/ecommerce/Contact'
import Footer from '~/components/partials/footer/ecommerce/Footer'
import toolbar from './toolbar'

export default {
    components: {
        Contact,
        Footer,
    },
    mixins: [toolbar],
    data () {
        return {
            accountAddress: `${process.env.PANEL_COMFINO_ORIGIN}/dashboard/`,
        }
    },
    computed: {
        ...mapFields(
            'ecommerce', [
                'store',
                'cart.products',
            ],
        ),
        ...mapFields(
            [
                'account',
            ],
        ),
    },
    watch: {
        async $route () {
            this.checkLogin()
        },
    },
    async created () {
        await this.checkLogin()
    },
    methods: {
        // means account is authenticated via password
        async checkLogin () {
            const response = await this.$accountAxios({
                method: 'get',
                url: '/logged-in-account',
                validateStatus: function (status) {
                    return status === 200 || status === 401
                },
            })
            if (response.status === 401) {
                return
            }

            this.account = response.data
        },
    },
}
</script>

<style lang="scss" scoped>
$toolbar-width: 320px;
$toolbar-width-lg: 432px;
.toolbar-desktop {
    min-width: $toolbar-width;
    max-width: $toolbar-width;
}
.toolbar {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 60px 54px 40px;
    width: $toolbar-width;
    height: 100vh;
    background: #fff;
    &__wrapper {
        display: flex;
        flex-direction: column;
        .logged-user {
          & > span {
            font-size: 8px;
            color: #7a7a7a;
          }
        }
        .logo {
            width: 190px;
        }
        .shop-logo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 4px;
            margin-left: 40px;
            font-size: 12px;
            color: #aaa;
            img {
                margin-top: 8px;
                height: 30px;
            }
        }
        .progress {
            margin-top: 140px;
            &__item {
                position: relative;
                margin-bottom: 20px;
                padding-left: 32px;
                font-size: 16px;
                line-height: 1;
                color: #aaaaaa;
                &.done {
                    color: #4a4a4a;
                }
                &.active {
                    color: #03af36;
                }
                .icon {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 16px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .footer {
            margin-top: auto;
        }
    }
}
@media (min-width: 1400px) {
    .toolbar-desktop {
        min-width: $toolbar-width-lg;
        max-width: $toolbar-width-lg;
    }
    .toolbar {
        width: $toolbar-width-lg;
    }
}
</style>

export default function () {
    const layout = window.location.pathname.split('/')[1]
    const href = '/favicon-comfino.svg'

    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
    }

    link.href = href
}

<template>
    <div
        class="wrapper"
        :class="{
            wide,
            colorful,
            center,
        }"
    >
        <div class="wrapper__animation">
        </div>
        <div class="page">
            <slot></slot>
        </div>
        <div class="sidebar hidden-md-and-down">
            <slot name="sidebar"></slot>
        </div>
        <Contact class="hidden-lg-and-up" />
    </div>
</template>

<script>
import Contact from '~/components/partials/contact/ecommerce/Contact'

export default {
    components: {
        Contact,
    },
    props: {
        wide: {
            type: Boolean,
            default: false,
        },
        colorful: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 1;
    .page{
        padding: 15px;
    }
    .sidebar {
        max-width: 225px;
    }
    background-attachment: fixed;
    background-size: cover;
    background-image: url('/backgrounds/background_ecommerce_mobile.jpg');
    &.colorful {
        background-image: url('/backgrounds/background_ecommerce_mobile_color.jpg');
        color: #fff;
        .wrapper__animation {
            display: block;
        }
    }
    > * {
        position: relative;
        z-index: 1;
    }
    &__animation {
        display: none;
        z-index: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        video {
            min-width: 100%;
            min-height: 100%;
        }
        &__desktop {
            display: none;
        }
    }
    .page {
        flex-grow: 1;
        margin: 0 auto;
        width: 100%;
        max-width: 768px;
    }
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .wrapper {
        flex-direction: row;
        padding-left: 32px;
        padding-right: 32px;
        .page {
            padding: 60px 32px 64px;
        }
        background-image: url('/backgrounds/background_ecommerce_desktop.jpg');
        &.colorful {
            background-image: url('/backgrounds/background_ecommerce_desktop_color.jpg');
        }
        &__animation {
            &__mobile {
                display: none;
            }
            &__desktop {
                display: block;
            }
        }
        &.wide {
            .page {
                padding-right: 0;
            }
        }
        &.center {
            .page {
                align-self: center;
            }
        }
        .page {
            margin: 0;
            max-width: 736px;
            padding-right: 128px;
        }
    }
}
</style>

<template>
    <PageWrapper class="wrapper" wide>
        <div class="content">
            <div class="content__logo-wrapper">
                <img src="~static/logo-comfino.svg" alt="logo Comfino" />
            </div>
            <h1>Ups... Nie wspieramy Twojej przeglądarki.</h1>
            <p class="content__info content__info--generic">Wygląda na to, że nie wspieramy przeglądarki z której korzystasz, użyj jednej ze wspieranych przeglądarek: Google Chrome, Microsoft Edge, Mozilla Firefox, Opera lub Safari.</p>
            <div class="content__browsers-list">
                <img src="~static/browsers/chrome.svg" alt="ikona przeglądarki Google Chrome" />
                <img src="~static/browsers/edge.svg" alt="ikona przeglądarki Microsoft Edge" />
                <img src="~static/browsers/mozilla.svg" alt="ikona przeglądarki Mozilla Firefox" />
                <img src="~static/browsers/opera.svg" alt="ikona przeglądarki Opera" />
                <img src="~static/browsers/safari.svg" alt="ikona przeglądarki Safari" />
            </div>
            <p class="content__info content__info--instructions">Poniżej znajdziesz link do Twojego wniosku, skopiuj go do schowka i otwórz w jednej z powyższych przeglądarek.</p>
            <input
                ref="urlInput"
                class="content__url-input"
                :class="inputClass"
                :value="currentUrl"
                @click="copyUrlToClipboard"
            />
            <button class="content__copy-button" @click="copyUrlToClipboard"><img src="~static/icons/copy.svg" alt="ikona kopiowania" />Skopiuj link</button>
        </div>
    </PageWrapper>
</template>
<script>
import PageWrapper from '~/components/partials/page-wrapper/ecommerce/PageWrapper'

export default {
    components: {
        PageWrapper,
    },
    layout: 'ecommerce/unsupported-browser',
    data: () => ({
        copySuccess: null,
    }),
    computed: {
        currentUrl () {
            return window.location.href
        },
        inputClass () {
            switch (this.copySuccess) {
                case true:
                    return 'content__url-input--success'
                case false:
                    return 'content__url-input--fail'
                default:
                    return ''
            }
        },
    },
    methods: {
        copyUrlToClipboard () {
            try {
                const input = this.$refs.urlInput
                input.select()
                document.execCommand('copy')
                this.copySuccess = true
            } catch (_) {
                this.copySuccess = false
                alert('Nie udało się skopiować linku')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.wrapper {
    min-height: 100vh;
    height: 100%;
    justify-content: center;
    align-items: center;

    :deep(.page) {
        display: flex;
        justify-content: center;
    }
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 640px;
    padding: 32px;
    gap: 24px;
    border-radius: 8px;
    color: #4A4A4A;
    background-color: white;

    &__logo-wrapper {
        width: 100%;
    }

    h1 {
        width: 100%;
        font-weight: 400;
    }

    &__info {
        margin: 0;

        &--generic {
            font-size: 16px;
            font-weight: 500;
        }

        &--instructions {
            padding-inline: 16px;
            font-size: 18px;
            text-align: center;
        }
    }

    &__browsers-list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 24px;
        gap: 24px;
        flex-wrap: wrap;
    }

    &__url-input {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #878787;
        text-align: center;
        color: #666666;
        background: white;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .08);
        transition: all .15s ease-in;

        &--success {
            border-color: green;
            background-color: rgba(0, 255, 0, .2)
        }

        &--fail {
            border-color: red;
            background-color: rgba(255, 0, 0, .2)
        }
    }

    &__copy-button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        min-width: 176px;
        padding: 14px 16px;
        gap: 8px;
        line-height: 1.3rem;
        font-size: 16px;
        border-radius: 6px;
        color: white;
        background: #03AF36;
    }
}
</style>
